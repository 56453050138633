<template>
  <en-table-layout
    :table-data="pageData.data"
    tooltip-effect="dark"
    @selection-change="handleSelectionChange"
    :loading="loading"
  >
    <template slot="toolbar">
      <el-form-item label="消息类型">
        <el-select
          v-if="$store.getters.user.shop_type === 1"
          v-model="messageType"
          clearable
          size="medium"
          placeholder="请选择"
        >
          <el-option label="售后通知" value="AFTERSALE"/>
          <el-option label="订单通知" value="ORDER"/>
        </el-select>
        <!-- 供应商和企业端-->
        <el-select
          v-else
          v-model="messageType"
          clearable
          size="medium"
          placeholder="请选择"
        >
          <el-option label="售后通知" value="AFTERSALE"/>
          <el-option label="订单通知" value="ORDER"/>
          <el-option label="平台通知" value="ADMIN"/>
        </el-select>
      </el-form-item>

      <div class="col-auto">
        <el-button size="small" type="primary" @click="GET_Message">搜索</el-button>
      </div>

      <div class="col"></div>

      <div class="col-auto">
        <el-button size="small" type="primary" @click="EDIT_Message">标为已读</el-button>
        <el-button size="small" type="primary" @click="REMOVE_Message">删除</el-button>
      </div>
    </template>

    <template slot="table-columns">
      <el-table-column align="center" type="selection" width="55"/>
      <el-table-column align="center" prop="title" label="标题内容">
        <template slot-scope="{row}">
          <el-button type="text" @click="onClickTitle(row)">
            <span class="unread" v-if="row.is_read !== 1">
              [未读]
            </span>
            <span>{{ row.title }}</span>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="send_time" label="提交时间" width="160">
        <template slot-scope="scope">{{ scope.row.send_time | unixToDate }}</template>
      </el-table-column>
      <el-table-column align="center" prop="type_text" label="消息类型"/>
    </template>

    <Pagination
      slot="pagination"
      :pageTotal="Number(pageData.data_total)"
      @handleCurrentChange="handlePageCurrentChange"
      @handleSizeChange="handlePageSizeChange"
    ></Pagination>
  </en-table-layout>
</template>

<script>
import Pagination from '@/components/Pagination';
import * as API_message from '@/api/info';
import {log} from 'util';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'orderList',
  components: {
    EnTableLayout,
    Pagination
  },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 筛选条件 */
      messageType: '',
      /** 表格 */
      pageData: {data: []},
      /** 点击后数组 */
      multipleSelection: [],
      /** 分页 */
      page_no: 1,
      page_size: 20,
    };
  },
  mounted() {
    this.GET_Message();
  },
  methods: {
    //页数发生改变
    handlePageCurrentChange(page) {
      this.page_no = page;
      this.GET_Message();
    },
    //每页显示数量发生变化
    handlePageSizeChange(size) {
      this.page_size = size;
      this.GET_Message();
    },
    //删除消息
    REMOVE_Message() {
      let _multipleSelection = this.multipleSelection;
      if (_multipleSelection.length === 0) {
        this.$message.error('请至少选择1条要删除的信息');
        return;
      }
      /** 列表参数 */
      let arr = [];
      arr.push(_multipleSelection.map(content => content.id).join(','));
      API_message.removeMessage(arr)
        .then(res => {
          this.$message.success('删除成功');
          setTimeout(() => {
            this.GET_Message();
          }, 2000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //标为已读
    EDIT_Message() {
      let _multipleSelection = this.multipleSelection;
      let is_read = _multipleSelection
        .map(content => content.is_read)
        .includes(1);
      if (_multipleSelection.length === 0) {
        this.$message.error('请至少选择1条要修改的信息');
        return;
      }
      if (!!is_read) {
        this.$message.error('请确认选中消息为未读状态');
        return;
      }
      let arr = [];
      arr.push(_multipleSelection.map(content => content.id).join(','));
      API_message.editMessage(arr)
        .then(res => {
          this.$message.success('已标为已读状态');
          setTimeout(() => {
            this.GET_Message();
          }, 2000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //选中数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 点击标题的时候
    onClickTitle(row) {
      // this.$message.success(row.notice_content)
      this.$alert(
        row.notice_content,
        `${row.title ? row.title : ''}（${row.type_text}）`,
        {
          confirmButtonText: '确定',
          confirmButtonSize: 'small',
          callback: action => {
            if (row.is_read === 0) {
              this.loading = true;
              API_message.editMessage(row.id)
                .then(res => {
                  // setTimeout(() => {
                  this.GET_Message();
                  // }, 2000);
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          }
        }
      );
    },
    /** 获取列表数据 */
    GET_Message() {
      this.loading = true;
      /** 列表参数 */
      let params = {
        page_no: this.page_no,
        page_size: this.page_size,
        type: this.messageType,
        ...this.$route.query
      };

      API_message.getMessage(params)
        .then(res => {
          this.loading = false;
          this.pageData = res;
          this.pageData.data = this.pageData.data.map(item => {
            let type = item.type;
            switch (type) {
              case 'AFTERSALE':
                item.type_text = '售后通知';
                break;
              case 'ORDER':
                item.type_text = '订单通知';
                break;
              case 'GOODS':
                item.type_text = '商品通知';
                break;
              case 'ADMIN':
                item.type_text = '平台通知';
                break;
              default:
                item.type_text = '其他通知';
                break;
            }
            return item;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.unread {
  color: #aa1111;
}
</style>
